<template>
  <div class="video-call-wrapper">
    <!-- loading start -->
    <v-overlay
      v-model="loading"
      contained
      class="align-center justify-center bg-red"
    >
      <div class="white pa-5 rounded-lg text-center">
        <p class="text--primary mb-0">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mr-3"
          ></v-progress-circular
          >{{ loadingMessage || "Please wait..." }}
        </p>
      </div>
    </v-overlay>
    <!-- loading end -->

    <!-- permission screen start -->
    <div class="permission-screen-wrapper" v-if="!isInCall && !isCallCompleted">
      <div class="permission-container row">
        <div class="col-4 pa-0">
          <div class="permission-title-container">
            <h3>Pinnacle Conference 2023</h3>
          </div>
        </div>
        <div class="col-8">
          <div class="permission-fields-container">
            <p class="title">Call Settings</p>
            <div class="row">
              <div class="col-6">
                <video
                  id="preview-video"
                  ref="videoElem"
                  autoplay
                  height="100%"
                  width="100%"
                />
              </div>
              <div class="col-6 mt-2">
                <v-select
                  v-model="selectedAudioInputDevice"
                  :items="audioInputDevices"
                  item-text="label"
                  item-value="deviceId"
                  label="Audio Input Device"
                  dense
                  outlined
                ></v-select>
                <v-select
                  v-model="selectedVideoInputDevice"
                  :items="videoInputDevices"
                  item-text="label"
                  item-value="deviceId"
                  label="Video Input Device"
                  dense
                  outlined
                ></v-select>
              </div>
            </div>
            <v-divider class="my-3" />
            <v-btn
              depressed
              color="primary"
              class="float-right"
              :disabled="
                !isPermissionGranted ||
                  !selectedAudioInputDevice ||
                  !selectedVideoInputDevice ||
                  isErrorOccured
              "
              :loading="isCreatingRoom || isCreatingToken || isJoiningCall"
              @click="handleJoinBtnClick"
            >
              Continue
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- permission screen end -->

    <!-- video participants screen start -->
    <div
      class="row"
      id="video-participants-container"
      v-show="isInCall && !isCallCompleted"
    />
    <!-- video participants screen end -->

    <!-- call actions start -->
    <div class="call-actions-container" v-show="isInCall && !isErrorOccured">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="red"
            v-bind="attrs"
            v-on="on"
            :disabled="loading"
            @click="endCall"
          >
            <v-icon dark>
              mdi-phone-hangup
            </v-icon>
          </v-btn>
        </template>
        <span>End Call</span>
      </v-tooltip>
    </div>
    <!-- call actions end -->

    <!-- end screen start -->
    <div class="end-call-screen-wrapper" v-show="isCallCompleted">
      <div class="end-call-screen-container">
        <v-icon color="red" size="40px">mdi-phone-classic-off</v-icon>
        <h5 class="end-call-message end-call-primary-message my-3">
          {{ callCompletedMessage || "Call Ended" }}
        </h5>
        <h6 class="end-call-message end-call-secondary-message my-3">
          {{ callCompletedMessageDescription }} You will soon be redirected to Home page in
          {{ redirectingInSeconds }} seconds
        </h6>
        <a @click="redirectToHome"
          ><v-icon style="color: inherit;" size="16px"
            >mdi-chevron-double-left</v-icon
          >
          Back to Home Page</a
        >
      </div>
    </div>
    <!-- end screen end -->

    <div class="error-container" v-if="isErrorOccured">
      Error : {{ errorTitle }}
    </div>
  </div>
</template>

<script>
import axios from "@/helper/axios";
import { getDeviceInfo, filterDevices } from "./Helper";
import Twilio from "twilio-video";
import WsSubscriptions from "../../helper/websocket";
import { mapState } from "vuex";

const redirectTimeInSeconds = 15;
let room = null;

export default {
  data() {
    return {
      loading: true,
      loadingMessage: "",
      isPermissionGranted: false,
      audioInputDevices: [],
      videoInputDevices: [],
      audioOutputDevices: [],
      hasAudioInputDevices: false,
      hasVideoInputDevices: false,
      selectedAudioInputDevice: null,
      selectedVideoInputDevice: null,
      isCreatingRoom: false,
      isCreatingToken: false,
      isJoiningCall: false,
      isErrorOccured: false,
      errorTitle: "",
      roomId: "",
      callId: "",
      token: "",
      isInCall: false,
      isCallCompleted: false,
      callCompletedMessage: "",
      callCompletedMessageDescription: "",
      redirectingInSeconds: redirectTimeInSeconds,
      redirectionIntervalId: null,
      loggedInUser: null,
      isAnotherUserHasJoined: false,
      sendedRoomEndedEvent: false
    };
  },
  computed: {
    ...mapState("utils", ["online_status"]),
  },
  watch: {
    online_status(isOnline) {
      if (isOnline) {
        this.subscribeToChannels();
      } else {
        this.unsubscribeFromChannels();
      }
    },
  },
  methods: {
    handleCallEvents(data) {
      if (data.type === "call_rejected") {
        this.callCompletedMessage = "Call Rejected";
        this.endCall();
      } else if (data.type === "callee_is_offline") {
        this.callCompletedMessage = `${data.calleeName} is Offline, So Call is Ended`;
        this.endCall();
      }
    },
    subscribeToChannels() {
      let subscription = this.$ws.socket.getSubscription(
        `user:${this.loggedInUser.attendee_id}`
      );

      if (!subscription) {
        // subscribing to user channel with attendee id topic
        this.$ws.subscribe(`user:${this.loggedInUser.attendee_id}`);

        this.$ws.$on(
          `user:${this.loggedInUser.attendee_id}|videocall`,
          this.handleCallEvents
        );
      }
    },
    unsubscribeFromChannels() {
      this.$ws.$off(`user:${this.loggedInUser.attendee_id}|videocall`);

      let subscription = this.$ws.socket.getSubscription(
        `user:${this.loggedInUser.attendee_id}`
      );

      if (subscription) {
        subscription.close();

        this.$ws.socket.removeSubscription(
          `user:${this.loggedInUser.attendee_id}`
        );
      }
    },
    createRoom(joineeId) {
      this.isCreatingRoom = true;

      axios
        .post("/call/start_call", {
          joinee_id: joineeId,
        })
        .then((res) => {
          const resData = res.data;

          if (resData.status) {
            this.callId = resData.callId;
            this.roomId = resData.roomId;

            this.createToken();
          } else {
            if (resData.error && resData.error.length > 0) {
              this.isErrorOccured = true;
              this.errorTitle =
                resData.error[0].message || "Something went wrong";
            } else {
              this.isErrorOccured = true;
              this.errorTitle = "Something went wrong";
            }
          }
        })
        .catch(() => {
          this.isErrorOccured = true;
          this.errorTitle = "Something went wrong";
        })
        .finally(() => {
          this.isCreatingRoom = false;
        });
    },
    createToken() {
      if (!this.callId) {
        console.log("ERROR - Room id not found");
        return;
      }

      this.isCreatingToken = true;

      axios
        .post(`/call/${this.callId}/token`)
        .then((res) => {
          const resData = res.data;

          if (resData.status) {
            this.token = resData.token;
            if (!this.roomId) {
              this.roomId = resData.room_id;
            }

            this.joinRoom();
          } else {
            this.isErrorOccured = true;
            this.errorTitle = "Something went wrong";
          }
        })
        .catch(() => {
          this.isErrorOccured = true;
          this.errorTitle = "Something went wrong";
        })
        .finally(() => {
          this.isCreatingToken = false;
        });
    },
    joinRoom() {
      this.isJoiningCall = true;

      Twilio.connect(this.token, {
        room: this.roomId,
        audio: {
          deviceId: this.selectedAudioInputDevice,
        },
        video: {
          deviceId: this.selectedVideoInputDevice,
        },
        name: "ParthPatel",
      })
        .then((roomDetails) => {
          this.isInCall = true;
          room = roomDetails;

          // using nexttick so html elements will be accesible
          this.$nextTick(() => {
            // render the local and remote participants' video and audio tracks
            this.handleConnectedParticipant(room.localParticipant);
           
            // disconnect the user is no one joins in 90 seconds
            setTimeout(() => {
              if (this.isInCall && !this.isAnotherUserHasJoined) {
                this.callCompletedMessage = "Call Automatically Disconnected";
                this.callCompletedMessageDescription = "As the receiver has not joined within 90 seconds."
                this.endCall();
              }
            }, 90000)

            if (room.participants && room.participants.size) {
              this.isAnotherUserHasJoined = true;
            }

            room.participants.forEach(this.handleConnectedParticipant);

            room.on("participantConnected", (participant) => {
              this.isAnotherUserHasJoined = true;
              this.handleConnectedParticipant(participant);
            });

            // handle cleanup when a participant disconnects
            room.on(
              "participantDisconnected",
              this.handleDisconnectedParticipant
            );

            room.on("disconnected", () => {
              room.participants.forEach(this.handleDisconnectedParticipant);

              // remove local participant
              const participantDiv = document.getElementById(room.localParticipant.identity);

              if (participantDiv) {
                participantDiv.remove();
              }

              room.localParticipant.tracks.forEach((publication) => {
                publication.unpublish();
                if (publication.track.kind !== "data") {
                  this.trackUnsubscribed(publication.track);
                }
              });

              this.isInCall = false;
              this.isCallCompleted = true;
              this.showRedirectionScreen();
            });
          });

          window.addEventListener("pagehide", () => {
            if (room) {
              this.sendCallEndedEvent();

              if (room.disconnect) {
                room.disconnect()
              }
            }

            // disconnect from websocket
            this.unsubscribeFromChannels();

            if (this.redirectionIntervalId) {
              clearInterval(this.redirectionIntervalId)
            }
          });
          window.addEventListener("beforeunload", () => {
            if (room) {
              this.sendCallEndedEvent();

              if (room.disconnect) {
                room.disconnect()
              }
            }

            // disconnect from websocket
            this.unsubscribeFromChannels();

            if (this.redirectionIntervalId) {
              clearInterval(this.redirectionIntervalId)
            }
          });
        })
        .catch(() => {
          console.log("ERROR - Cannot connect to room");

          this.isErrorOccured = true;
          this.errorTitle =
            "Cannot able to connect to join room, Please try again";
        })
        .finally(() => {
          this.isJoiningCall = false;
        });
    },
    handleConnectedParticipant(participant) {
      // create a div for this participant's tracks
      const participantDiv = document.createElement("div");
      participantDiv.setAttribute("id", participant.identity);
      participantDiv.className = "col col-md-6 col-6";

      try {
        const participantData = JSON.parse(participant.identity);

        participantDiv.setAttribute("data-aid", participantData.id);
        participantDiv.setAttribute("data-fname", participantData.fname);
        participantDiv.setAttribute("data-lname", participantData.lname);

        participantDiv.innerHTML = `<div class="video-wrapper">
            <h5 class="participant-name">${participantData.fname} ${participantData.lname}</h5>
          </div>`;
      } catch (error) {
        console.log(
          "ERROR - Something went wrong while parsing participant data"
        );
      }

      const container = document.getElementById("video-participants-container");

      if (container) {
        container.appendChild(participantDiv);
      }

      // iterate through the participant's published tracks and
      // call `handleTrackPublication` on them
      participant.tracks.forEach((trackPublication) => {
        this.handleTrackPublication(trackPublication, participant);
      });

      // add published track
      participant.on("trackPublished", this.handleTrackPublication);
    },
    handleTrackPublication(trackPublication, participant) {
      function displayTrack(track) {
        const participantDiv = document.getElementById(participant.identity);

        if (participantDiv && participantDiv.childNodes.length) {
          // adds video and/or audio stream (creates html element)
          try {
            participantDiv.childNodes[0].append(track.attach());
          } catch (error) {
            console.log("ERROR - Track cannot be added")
          }
        }
      }

      if (trackPublication.track) {
        displayTrack(trackPublication.track);
      }

      // listen for any new subscriptions to this track publication
      trackPublication.on("subscribed", displayTrack);

      // remove unpublished track
      trackPublication.on('unsubscribed', this.trackUnsubscribed);
    },
    handleDisconnectedParticipant(participant) {
      // stop listening for this participant
      participant.removeAllListeners();

      // remove this participant's div from the page
      const participantDiv = document.getElementById(participant.identity);

      if (participantDiv) {
        participantDiv.remove();
      }
    },
    trackUnsubscribed(track) {
      try {
        track.detach().forEach((element) => {
          element.srcObject = null;
          element.remove()
        });

        // fallback for safari
        track._attachments?.forEach((detachedElement) =>
          detachedElement.remove()
        );
      } catch (error) {
        console.log("ERROR: UNSUBSCRIBING_TRACKS");
      }
    },
    setDeviceData(data) {
      this.audioInputDevices = filterDevices(data.audioInputDevices);
      this.videoInputDevices = filterDevices(data.videoInputDevices);
      this.audioOutputDevices = filterDevices(data.audioOutputDevices);
      this.hasAudioInputDevices = data.hasAudioInputDevices;
      this.hasVideoInputDevices = data.hasVideoInputDevices;

      if (!this.selectedAudioInputDevice && this.audioInputDevices.length) {
        this.selectedAudioInputDevice = this.audioInputDevices[0].deviceId;
      }

      if (!this.selectedVideoInputDevice && this.videoInputDevices.length) {
        this.selectedVideoInputDevice = this.videoInputDevices[0].deviceId;
      }
    },
    requestMediaDevice(refreshDeviceList = false) {
      // devices found so asking for permission to access audio and video
      navigator.mediaDevices
        .getUserMedia({
          audio: { deviceId: this.selectedAudioInputDevice || null },
          video: {
            facingMode: "user",
            deviceId: this.selectedVideoInputDevice || null,
          },
        })
        .then((stream) => {
          this.isPermissionGranted = true;

          if (this.$refs.videoElem) {
            this.$refs.videoElem.srcObject = stream;
          }

          if (refreshDeviceList) {
            // refresh the list of audio and video devices
            getDeviceInfo().then((data) => {
              this.setDeviceData(data);
            });
          }
        })
        .catch(() => {
          this.isErrorOccured = true;
          this.errorTitle = "Something went wrong";
        });
    },
    handleJoinBtnClick() {
      if (this.$route.query.callId) {
        this.callId = this.$route.query.callId;
        this.createToken();
      } else {
        this.createRoom(this.$route.query.joineeId);
      }
    },
    endCall() {
      if (!this.callId) {
        return;
      }

      this.loading = true;

      axios
        .put(`/call/${this.callId}/end_call`)
        .then((res) => {
          const resData = res.data;

          if (resData.status) {
            this.sendCallEndedEvent();
            this.isInCall = false;
            this.isCallCompleted = true;
            this.showRedirectionScreen();
          } else {
            this.isErrorOccured = true;
            this.errorTitle = "Something went wrong";
          }
        })
        .catch(() => {
          this.isErrorOccured = true;
          this.errorTitle = "Something went wrong";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showRedirectionScreen() {
      this.redirectingInSeconds = redirectTimeInSeconds;

      if (this.redirectionIntervalId) {
        clearInterval(this.redirectionIntervalId);
      }

      this.redirectionIntervalId = setInterval(() => {
        if (this.redirectingInSeconds == 0) {
          this.redirectToHome();
        }
        this.redirectingInSeconds = this.redirectingInSeconds - 1;
      }, 1000);
    },
    redirectToHome() {
      if (this.redirectionIntervalId) {
        clearInterval(this.redirectionIntervalId);
      }

      this.$router.replace({ path: "/app/" });
    },
    sendCallEndedEvent() {
      if (room) {
        const socket = this.$ws.socket.getSubscription(
          `user:${this.loggedInUser.attendee_id}`
        );

        if (socket && !this.sendedRoomEndedEvent) {
          const data = {
            type: 'call_ended',
            callId: this.callId,
            roomId: this.roomId,
          }

          socket.emit('videocall', data)

          this.sendedRoomEndedEvent = true;
        }
      }
    }
  },
  async created() {
    this.loggedInUser = JSON.parse(localStorage.getItem("pinnacle_user"));

    // connect to websocket
    await WsSubscriptions(this.loggedInUser.attendee_token);
  },
  beforeDestroy() {
    // disconnect from the twilio room
    if (room) {
      this.sendCallEndedEvent();
      room.disconnect ? room.disconnect() : null;
    }

    // disconnect from websocket
    this.unsubscribeFromChannels();

    if (this.redirectionIntervalId) {
      clearInterval(this.redirectionIntervalId)
    }
  },
  mounted() {
    room = null;

    if (!this.$route.query.joineeId && !this.$route.query.callId) {
      this.isErrorOccured = true;
      this.errorTitle =
        "Required information not found to make call, Please try again";
      this.loading = false;
      return;
    }

    // get list of audio and video devices
    getDeviceInfo()
      .then((data) => {
        // no devices found
        if (!data.hasVideoInputDevices || !data.hasAudioInputDevices) {
          this.isErrorOccured = true;
          this.errorTitle =
            "Audio or Video device not found, Please connect audio and video device and continue";
          return;
        }

        this.setDeviceData(data);

        // adding watchers to request permission from user on selected device change
        this.$watch("selectedAudioInputDevice", function(deviceId) {
          if (deviceId) {
            this.requestMediaDevice();
          }
        });

        this.$watch("selectedVideoInputDevice", function(deviceId) {
          if (deviceId) {
            this.requestMediaDevice();
          }
        });

        this.requestMediaDevice(true);
      })
      .catch((error) => {
        setTimeout(() => {
          this.isErrorOccured = true;
          this.errorTitle = error.error_code
            ? error.error_msg
            : "Something went wrong";
        }, 1000);
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
  },
};
</script>

<style lang="scss" scoped>
.video-call-wrapper {
  --primary-text-color: rgba(255, 255, 255, 0.75);

  width: 100%;
  height: 100%;
  background: #011a39;

  .permission-screen-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.permission-container {
  max-width: 700px;
  background: #ffffff;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;

  .permission-title-container {
    display: flex;
    align-items: center;
    background: #003a80;
    height: 100%;
    color: var(--primary-text-color);
    text-align: center;
    padding: 15px;

    h3 {
      font-weight: 400;
      font-size: 19px;
    }
  }

  .permission-fields-container {
    background: white;
    padding: 10px;

    .title {
      font-size: 20px;
      font-weight: 400;
    }

    .preview-video-container {
      position: relative;
    }

    #preview-video {
      height: 150px;
      width: 100%;
      object-fit: cover;
      background-color: #e5e5e5;
    }
  }
}

.error-container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  background: #760000;
  color: white;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  animation: slide-up 1.3s ease-in-out;
}

.call-actions-container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  background: #003a80;
  color: white;
  font-size: 16px;
  padding: 8px;
  text-align: center;
  animation: slide-up 1.3s ease-in-out;
}

@keyframes slide-up {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 0px;
  }
}

.end-call-screen-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .end-call-screen-container {
    max-width: 450px;
    text-align: center;
    background: #00234d;
    padding: 20px;
    border-radius: 15px;

    a {
      text-decoration: none;
      color: lightblue;
      font-size: 14px;
    }

    .end-call-message {
      font-weight: 400;
      text-align: center;
      width: 100%;
    }

    .end-call-primary-message {
      font-size: 20px;
      color: var(--primary-text-color);
      text-align: center;
    }

    .end-call-secondary-message {
      font-size: 14px;
      color: lightblue;
    }
  }
}
</style>

<style lang="scss">
#video-participants-container {
  height: 100%;
  margin: 0px;
  padding: 20px;

  div {
    height: fit-content;
    border-radius: 15px;
    overflow: hidden;
  }

  .col video {
    border-radius: 15px !important;
    width: 100% !important;
  }

  .video-wrapper {
    position: relative;

    .participant-name {
      position: absolute;
      bottom: 15px;
      left: 15px;
      background: rgba(0, 0, 0, 0.7);
      padding: 5px 10px;
      border-radius: 5px;
      color: whitesmoke;
      letter-spacing: 0.5px;
      font-size: 14px;
    }
  }
}
</style>
